import React, { useState } from "react";
import { Link } from "react-router-dom";

const YouthProgramsForm = () => {
  const [formState, setFormState] = useState({
    organization: "",
    name: "",
    email: "",
    phone: "",
    groupSize: "",
    message: "",
    ageRange: "",
    interest: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(
      "https://habitat-buffalo-4b593fbcd470.herokuapp.com/submit-youth-program",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formState),
      }
    )
      .then((response) => {
        if (response.ok) {
          setFormSubmitted(true);
        } else {
          throw new Error("Form submission failed");
        }
      })
      .catch((error) => {
        alert("There was an error submitting the form.");
      });
  };

  if (formSubmitted) {
    return (
      <div>
        <h2>Thank You!</h2>
        <p>
          Your submission has been received. We will get back to you shortly.
        </p>
        <Link to="/">Return to Home</Link>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Organization:
        <input
          type="text"
          name="organization"
          value={formState.organization}
          onChange={handleInputChange}
          required
        />
      </label>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={formState.name}
          onChange={handleInputChange}
          required
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={formState.email}
          onChange={handleInputChange}
          required
        />
      </label>
      <label>
        Phone:
        <input
          type="tel"
          name="phone"
          value={formState.phone}
          onChange={handleInputChange}
          required
        />
      </label>
      <label>
        Group Size:
        <input
          type="text"
          name="groupSize"
          value={formState.groupSize}
          onChange={handleInputChange}
          required
        />
      </label>
      <label>
        Age Range:
        <input
          type="text"
          name="ageRange"
          value={formState.ageRange}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Interest:
        <input
          type="text"
          name="interest"
          value={formState.interest}
          onChange={handleInputChange}
        />
      </label>
      <label>
        Additional Information:
        <textarea
          name="message"
          value={formState.message}
          onChange={handleInputChange}
        ></textarea>
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default YouthProgramsForm;
