import React, { useState } from "react";
import { Link } from "react-router-dom";

const HomeRepairForm = () => {
  const [formState, setFormState] = useState({
    isOwner: "",
    isPrimaryResidence: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    address: "",
    city: "",
    zip: "",
    income: "",
    householdSize: "",
    repairDescription: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://habitat-buffalo-4b593fbcd470.herokuapp.com/submit-repair-program",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formState),
        }
      );

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      alert("There was a problem submitting the form. Please try again.");
    }
  };

  if (formSubmitted) {
    return (
      <div>
        <h2>Thank You!</h2>
        <p>
          Your submission has been received. We will get back to you shortly.
        </p>
        <Link to="/">Return to Home</Link>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Are you the current owner of this home?
        <select
          name="isOwner"
          value={formState.isOwner}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </label>
      <label>
        Is this your primary residence?
        <select
          name="isPrimaryResidence"
          value={formState.isPrimaryResidence}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </label>
      <label>
        First Name:
        <input
          type="text"
          name="firstName"
          value={formState.firstName}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Last Name:
        <input
          type="text"
          name="lastName"
          value={formState.lastName}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Phone:
        <input
          type="tel"
          name="phone"
          value={formState.phone}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={formState.email}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Date of Birth:
        <input
          type="date"
          name="dob"
          value={formState.dob}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Address:
        <input
          type="text"
          name="address"
          value={formState.address}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        City:
        <input
          type="text"
          name="city"
          value={formState.city}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        ZIP Code:
        <input
          type="text"
          name="zip"
          value={formState.zip}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Annual Income:
        <input
          type="number"
          name="income"
          value={formState.income}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Household Size:
        <input
          type="number"
          name="householdSize"
          value={formState.householdSize}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Repair Description:
        <textarea
          name="repairDescription"
          value={formState.repairDescription}
          onChange={handleChange}
          required
        ></textarea>
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default HomeRepairForm;
