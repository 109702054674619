import React from "react";
import Layout from "./components/Layout";
import GlobalStyle from "./globalStyles";

const App = ({ children }) => (
  <>
    <GlobalStyle />
    {window.location.pathname !== "/connect" ? (
      <Layout>{children}</Layout>
    ) : (
      <>{children}</> // Render only the children for the Connect page
    )}
  </>
);

export default App;
